import React from 'react'
import { HeaderProps } from 'types/props'
import Icon from 'components/common/icon'
import style from './form-header.module.scss'

const FormHeader = (props: HeaderProps) => {
  return (
    <div data-testid="auth-form-header">
      {props.icon &&
        <div className={style['form-header']}>
          <Icon className={style['icon']} name={props.icon} />
        </div>
      }
      <h3 className={style['title']} dangerouslySetInnerHTML={{ __html: props.title }} />
      {props.text && <div role="text" className={style['text']}>{props.text}</div>}
    </div>
  )
}

export default FormHeader
